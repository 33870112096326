import React from "react";
import Card from "./Card";
import styled from "styled-components";
import Heading from "./Heading";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 50px 0 50px 0;
  color: #048b57;
  @media (max-width: 850px) {
    margin: 10px 0 50px 0;
  }
`;

const ExclusiveProgram = (probs) => {
  const { data, head1, head2 } = probs;
  //console.log("Exclusive comp Clicked");
  return (
    <StyledDiv className="font-fam">
      <Heading mainHeading={head1} subHeading={head2} />
      <Card data={data} head1={head1} />
    </StyledDiv>
  );
};

export default ExclusiveProgram;
