import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledImg = styled.img`
  width: 100%;
  height: 350px;
  border-radius: 20px;
  background-size: cover;
  margin-bottom: 10px;
`;
const StyledUl = styled.ul`
  display: flex;
  gap: 100px;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  margin-top: 35px;
  font-family: "Poppins";
`;
const StyledDiv = styled.div`
  width: 350px;
`;
const StyledSpan = styled.span`
  color: black;
  font-size: 20px;
`;
const Card = ({ data, head1 }) => {
  const navigate = useNavigate();
  const handleClick = (param, index) => {
    navigate("./experiments", { state: { moduleName: param, index, head1 } });
    //console.log("Hi Card Comp Clicked");
  };
  return (
    <>
      <StyledUl>
        {data.map((val, index) => {
          return (
            <StyledDiv
              key={index}
              style={{ width: "350px" }}
              onClick={() => handleClick(val.moduleName, index)}
            >
              <StyledImg src={val.imageName} alt={index} className="card" />
              <StyledSpan>
                <b>{val.moduleName}</b>
              </StyledSpan>
            </StyledDiv>
          );
        })}
      </StyledUl>
    </>
  );
};

export default Card;
