import axios from "axios";
export const usePostApi = () => {
  return async (params, apiUrl) => {
    try {
      //console.log("Params", params, apiUrl);
      const response = await axios.post(
        `${process.env.REACT_APP_LOCAL_HOST}${apiUrl}`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      //console.log("Enternal Register Error");
      return {
        error: error.response ? error.response.data : "An error occurred",
      };
    }
  };
};
