import myCard1 from "./Images/myCard1.jpeg";
import myCard2 from "./Images/myCard2.jpeg";
import myCard3 from "./Images/myCard3.jpeg";
import myCard4 from "./Images/myCard4.jpeg";
import myCard5 from "./Images/myCard5.jpeg";
import myCard6 from "./Images/myCard6.jpeg";
import myCard7 from "./Images/myCard7.jpeg";
import myCard8 from "./Images/myCard8.jpeg";
import myCard9 from "./Images/myCard9.jpeg";
const data = [
  { imageName: myCard1, moduleName: "Microbioloy(MY)" },
  { imageName: myCard2, moduleName: "Plant Biotechnology(PB)" },
  { imageName: myCard3, moduleName: "Molecular Biology(MB)" },
  { imageName: myCard4, moduleName: "Fermentation/Enzyme Technology(FT)" },
  { imageName: myCard5, moduleName: "Biochemistry(BC)" },
  { imageName: myCard6, moduleName: "Herbal Product Development(HPD)" },
  { imageName: myCard7, moduleName: "Food Technology(FDT)" },
  { imageName: myCard8, moduleName: "Pharmaceutical Technology(PT)" },
  { imageName: myCard9, moduleName: "Immunology(IM)" },
];
export default data;
