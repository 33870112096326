import React from "react";
import dna from "../Images/dna.jpeg";
import FirstImageText from "./FirstImageText";

const Home = () => {
  return (
    <div style={{ position: "relative" }}>
      <img
        src={dna}
        alt="DNA"
        className="firstImageStyle animation-apply-btn"
      />
      <FirstImageText />
    </div>
  );
};

export default Home;
