import React from "react";
import cells from "../Images/cells.jpeg";
import styled from "styled-components";
const StyledDiv = styled.div`
  display: flex;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;
const StyledSec = styled.section`
  width: 50%;

  @media (max-width: 850px) {
    width: 100%;
    padding: 2px;
  }
`;
const StyledImg = styled.img`
  width: 100%;
  height: 500px;
  padding: 30px;
  border-radius: 50px;
  @media (max-width: 850px) {
    width: 100%;
    height: unset;
  }
`;
const StyledSecText = styled.section`
  width: 50%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  justify-content: center;
  @media (max-width: 850px) {
    width: 95%;
  }
`;
const SecondImage = () => {
  return (
    <>
      <StyledDiv className="gradient-color">
        <StyledSec>
          <StyledImg
            className="animation-apply-image-second"
            src={cells}
            alt="Cells"
          />
        </StyledSec>
        <StyledSecText>
          <h1>Meet Atmohive Biotech &trade;</h1>
          <p style={{ fontSize: "18px" }}>
            We offer specialized training programs designed for students,
            professionals, and enthusiasts in the field of biotechnology and
            bioinformatics. Choose the training and projects that best suits
            your needs.
          </p>
        </StyledSecText>
      </StyledDiv>
    </>
  );
};

export default SecondImage;
