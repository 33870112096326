import React from "react";
import { useNavigate } from "react-router-dom";

const FirstImageText = () => {
  const navigate = useNavigate();
  const handleRegister = () => {
    navigate("/registernow");
  };
  return (
    <div
      style={{
        position: "absolute",
        top: "40%",
        left: "20px",
        fontFamily: "Poppins",
      }}
    >
      <h1 style={{ fontSize: "50px" }}>Empower Your Future</h1>
      <h4 style={{ margin: "20px 0px" }}>
        Join Hands-on Training Programs at our State-of-the-Art Laboratory
      </h4>
      <button
        type="button"
        className="btn btn-light fw-bold animation-apply-btn"
        style={{
          borderRadius: "30px",
          padding: "10px 20px",
          color: "blue",
        }}
        onClick={handleRegister}
      >
        Register now
      </button>
    </div>
  );
};

export default FirstImageText;
