import myCard1Other from "./Images/myCard1Other.jpeg";
import myCard2Other from "./Images/myCard2Other.jpeg";
import myCard3Other from "./Images/myCard3Other.jpeg";
import myCard4Other from "./Images/myCard4Other.jpeg";
import myCard5Other from "./Images/myCard5Other.jpeg";
import myCard6Other from "./Images/myCard6Other.jpeg";
const data2 = [
  { imageName: myCard1Other, moduleName: "Dry Lab Hands-On Training" },
  { imageName: myCard2Other, moduleName: "Integrative Training Programs" },
  { imageName: myCard3Other, moduleName: "Projects" },
  { imageName: myCard4Other, moduleName: "Laboratory Testing" },
  {
    imageName: myCard5Other,
    moduleName: "Research Writing and Career Consultancy",
  },
  { imageName: myCard6Other, moduleName: "Workshops and Conferences" },
];
export default data2;
