import React from "react";
import Home from "./Home";
import SecondImage from "./SecondImage";
import AboutUs from "./AboutUs";
import ExclusiveProgram from "./ExclusiveProgram";
import data from "../data";
import data2 from "../data2";
import OurAssociations from "./OurAssociations";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import Founders from "./Founders";
import NavbarComp from "./NavbarComp";

const MainComp = () => {
  return (
    <>
      <NavbarComp />
      <Home />
      <SecondImage />
      <AboutUs />
      <ExclusiveProgram
        data={data}
        head1={process.env.REACT_APP_EXC}
        head2={process.env.REACT_APP_WET}
      />
      <ExclusiveProgram
        data={data2}
        head1={process.env.REACT_APP_OTH}
        head2={process.env.REACT_APP_ATMH}
      />
      <Founders />
      <OurAssociations />
      <ContactUs />
      <Footer />
    </>
  );
};

export default MainComp;
