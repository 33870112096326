import React from "react";
import styled from "styled-components";
import Heading from "./Heading";

const StyledDiv = styled.div`
  display: flex;
  height: 25vh;
  justify-content: space-around;
  padding: 20px;
  @media (max-width: 850px) {
    flex-direction: column;
    height: 50vh;
    align-items: center;
  }
`;
const StyledSpan = styled.span`
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 2px solid black;
  border-radius: 10px;
  justify-content: center;
  width: 30%;
  @media (max-width: 850px) {
    width: 90%;
  }
`;
const OurAssociations = () => {
  return (
    <>
      <Heading mainHeading={process.env.REACT_APP_OUR_ASO} />
      <StyledDiv className="gradient-color font-fam">
        <StyledSpan>
          <h4>HBTU-TBI Foundation</h4>
          <h5>(Incubated Under Zero-Rental Scheme)</h5>
        </StyledSpan>
        <StyledSpan>
          <h4>Dr. Prekshe S Garg</h4>
          <a
            href={"https://www.prekshesgarg.com"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <b>(www.prekshesgarg.com)</b>
          </a>
        </StyledSpan>
      </StyledDiv>
    </>
  );
};

export default OurAssociations;
