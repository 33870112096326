import React, { memo } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  text-align: center;
  margin: 50px 0 50px 0;
  color: #048b57;
  font-size: 50px;
  @media (max-width: 850px) {
    font-size: 20px;
  }
`;

const Heading = (probs) => {
  const { mainHeading, subHeading } = probs;
  //console.log("Heading Component Clicked");
  return (
    <StyledDiv className="opacity">
      {mainHeading && <b>{mainHeading}</b>}
      {subHeading && <h2 className="opacity">{subHeading}</h2>}
    </StyledDiv>
  );
};

export default memo(Heading);
