import { lazy } from "react";
import NavbarComp from "./component/MainComp";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Suspense } from "react";
import Loader from "./component/Loader";
import RegisterNow from "./component/RegisterNow";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import PageNotFound from "./component/PageNotFound";

const ExperimentDetails = lazy(() => import("./component/ExperimentDetails"));
const ResAndRecod = lazy(() => import("./component/ResAndRecod"));

function App() {
  return (
    <Router>
      <ToastContainer />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<NavbarComp />} />
          <Route path="/experiments" element={<ExperimentDetails />} />
          <Route path="/research" element={<ResAndRecod />} />
          <Route path="/registernow" element={<RegisterNow />} />
          <Route path="*" element={<PageNotFound />}></Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
