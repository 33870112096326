import React from "react";
import Mohit from "../Images/Mohit.jpeg";
import Saijasi from "../Images/Saijasi.JPG";
import Heading from "./Heading";
import styled from "styled-components";

const StyledImage = styled.img`
  width: 220px;
  height: 220px;
  border-radius: 50%;
  margin-bottom: 10px;
`;
const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 20%;
  padding-right: 20%;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;
const StyledDivNext = styled.div`
  text-align: center;
  @media (max-width: 850px) {
    margin-bottom: 20px;
  }
`;
const StyledMainDiv = styled.div`
  font-family: Poppins;
`;

const Founders = () => {
  return (
    <StyledMainDiv>
      <Heading mainHeading={process.env.REACT_APP_FOUNDER} />
      <StyledDiv>
        <StyledDivNext>
          <StyledImage src={Mohit} alt="Mohit" className="box-shadow" />
          <div>
            <b>Mohit Nigam</b>
          </div>
          <div>
            <b>Co-Founder & Director</b>
          </div>
          <div>
            <b>Pursuing Ph.D. in Biochemical Engineering</b>
          </div>
          <div>
            <b>HBTU, Kanpur</b>
          </div>
        </StyledDivNext>
        <StyledDivNext>
          <StyledImage src={Saijasi} alt="Saijasi" className="box-shadow" />
          <div>
            <b>Dr. Saijasi Dubey</b>
          </div>
          <div>
            <b>Co-Founder & Director</b>
          </div>
          <div>
            <b>Ph.D. in Biotechnology</b>
          </div>
          <div>
            <b>Amity University, Lucknow</b>
          </div>
        </StyledDivNext>
      </StyledDiv>
    </StyledMainDiv>
  );
};

export default Founders;
