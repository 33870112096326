import React, { useReducer } from "react";
import Heading from "./Heading";
import styled from "styled-components";
import { useToast } from "./useToast";
import { usePostApi } from "../Api/usePostApi";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  align-items: center;
`;
const StyledInput = styled.input`
  margin-bottom: 20px;
  border-radius: 20px;
  padding: 15px;
  width: 50%;
  border: 2px solid #048b57;
  @media (max-width: 850px) {
    width: 90%;
  }
`;
const StyledTextArea = styled.textarea`
  border-radius: 20px;
  padding: 15px;
  width: 50%;
  border: 2px solid #048b57;
  margin-bottom: 20px;
  @media (max-width: 850px) {
    width: 90%;
  }
`;
const StyledButton = styled.button`
  width: 10%;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid #048b57;
  font-family: "Poppins";
  @media (max-width: 850px) {
    width: 60%;
  }
`;

const initialState = {
  name: "",
  email: "",
  phone: "",
  query: "",
};
const reducer = (state, action) => {
  switch (action.type) {
    case "Input Update":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
const ContactUs = () => {
  const constUsApi = usePostApi();
  const showToast = useToast();
  const [state, dispatch] = useReducer(reducer, initialState);
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    dispatch({ type: "Input Update", field: name, value });
  };
  const handleClickContact = async (e) => {
    e.preventDefault();
    const response = await constUsApi(state, process.env.REACT_APP_CONTACT_API);
    if (response?.success) {
      showToast(response?.msg, 2500, "😅", "top-right");
    } else if (!response?.error?.success) {
      const data = response?.error?.errors?.errors;
      showToast(data[0]?.msg, 2500, "😅", "top-right");
    }
  };
  //console.log(state);
  return (
    <>
      <Heading mainHeading={process.env.REACT_APP_CONTACT} />
      <StyledForm className="Poppins">
        <StyledInput
          type="text"
          placeholder="Name*"
          name="name"
          value={state.name}
          onChange={handleChangeInput}
        />
        <StyledInput
          type="email"
          placeholder="Email*"
          name="email"
          value={state.email}
          onChange={handleChangeInput}
        />
        <StyledInput
          type="phone"
          placeholder="Phone Number*"
          name="phone"
          maxLength="10"
          minLength="10"
          value={state.phone}
          onChange={handleChangeInput}
        />
        <StyledTextArea
          type="text"
          placeholder="Query*"
          rows="5"
          cols="50"
          name="query"
          value={state.query}
          onChange={handleChangeInput}
        />
        <StyledButton
          type="button"
          className="btn btn-success"
          onClick={handleClickContact}
        >
          Submit
        </StyledButton>
      </StyledForm>
    </>
  );
};

export default ContactUs;
