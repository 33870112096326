import React from "react";
import loader from "../Images/Loader.gif";
import styled from "styled-components";
const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
`;
const Loader = () => {
  return (
    <StyledDiv>
      <img src={loader} alt="Loader" />
    </StyledDiv>
  );
};

export default Loader;
