import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
`;
const PageNotFound = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <>
      <hr style={{ marginTop: "0px" }} />
      <StyledDiv>
        <h1>Oops!</h1>
        <h2>404 Not Found</h2>
        <div>Sorry, an error has occured, Requested page not found!</div>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleClick}
          >
            Home
          </button>
        </div>
      </StyledDiv>
    </>
  );
};

export default PageNotFound;
