import React from "react";
import mainLogo from "../Images/mainlogo.jpeg";
import { Link, useNavigate } from "react-router-dom";
const NavbarComp = () => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth", // Optional: Adds a smooth scrolling effect
    });
  };
  const handleHomeClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds a smooth scrolling effect
    });
  };
  const handleServiceClick = (e, param, index, head1) => {
    e.preventDefault();
    navigate("/experiments", { state: { moduleName: param, index, head1 } });
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              className="mainlogo"
              src={mainLogo}
              alt="Atmohive Biotech Pvt. Ltd."
            />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  id="font-family-poppins"
                  aria-current="page"
                  to="/"
                  onClick={handleHomeClick}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  id="font-family-poppins"
                  aria-current="page"
                  to="/research"
                >
                  Research and Recognitions
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  id="font-family-poppins"
                  aria-current="page"
                  to="/registernow"
                >
                  Register Now
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  id="font-family-poppins"
                  aria-current="page"
                  to="/"
                  onClick={handleClick}
                >
                  Contact Us
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  id="font-family-poppins"
                  aria-current="page"
                  className="nav-link dropdown-toggle text-dark"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  type="dropdown"
                >
                  Services
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(e, "Microbioloy(MY)", 0, "")
                      }
                    >
                      Microbioloy(MY)
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(e, "Plant Biotechnology(PB)", 1, "")
                      }
                    >
                      Plant Biotechnology(PB)
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(e, "Molecular Biology(MB)", 2, "")
                      }
                    >
                      Molecular Biology(MB)
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(
                          e,
                          "Fermentation/Enzyme Technology(FT)",
                          3,
                          ""
                        )
                      }
                    >
                      Fermentation/Enzyme Technology(FT)
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(e, "Biochemistry(BC)", 4, "")
                      }
                    >
                      Biochemistry(BC)
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(
                          e,
                          "Herbal Product Development(HPD)",
                          5,
                          ""
                        )
                      }
                    >
                      Herbal Product Development(HPD)
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(e, "Food Technology(FDT)", 6, "")
                      }
                    >
                      Food Technology(FDT)
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(
                          e,
                          "Pharmaceutical Technology(PT)",
                          7,
                          ""
                        )
                      }
                    >
                      Pharmaceutical Technology(PT)
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(e, "Immunology(IM)", 8, "")
                      }
                    >
                      Immunology(IM)
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(
                          e,
                          "Dry Lab Hands-On Training",
                          0,
                          "OTHER SERVICES"
                        )
                      }
                    >
                      Dry Lab Hands-On Training
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(
                          e,
                          "Integrative Training Programs",
                          1,
                          "OTHER SERVICES"
                        )
                      }
                    >
                      Integrative Training Programs
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(e, "Projects", 2, "OTHER SERVICES")
                      }
                    >
                      Projects
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(
                          e,
                          "Laboratory Testing",
                          3,
                          "OTHER SERVICES"
                        )
                      }
                    >
                      Laboratory Testing
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(
                          e,
                          "Research Writing and Career Consultancy",
                          4,
                          "OTHER SERVICES"
                        )
                      }
                    >
                      Research Writing and Career Consultancy
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={(e) =>
                        handleServiceClick(
                          e,
                          "Workshops and Conferences",
                          5,
                          "OTHER SERVICES"
                        )
                      }
                    >
                      Workshops and Conferences
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarComp;
