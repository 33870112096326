import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  color: red;
  border: 2px solid red;
  text-align: center;
  border-radius: 10px;
`;

const TextCopied = () => {
  return (
    <>
      <StyledDiv>copied</StyledDiv>
    </>
  );
};

export default TextCopied;
